<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-col flex="auto">
    <a-card>
      <a-tabs type="card" v-model:activeKey="state.fromKey" @change="changeBrandKey">
        <a-tab-pane key=1 tab="添加车辆品牌">
          <div style="width:500px">
            <a-form ref="formRefBrand" :model="state.form" :rules="state.rules" :labelCol="{flex:'100px'}" :wrapperCol="{flex:'auto'}">
              <a-form-item label="车辆品牌" name="brand">
                <a-input v-model:value="state.form.brand" />
              </a-form-item>
              <a-form-item>
                <a-row>
                  <a-col :span="24" style="text-align: right;margin-top:20px">
                    <a-button type="primary" :loading="state.confirmLoading" @click.prevent="save">添加车辆品牌</a-button>
                  </a-col>
                </a-row>
              </a-form-item>

            </a-form>
          </div>

        </a-tab-pane>
        <a-tab-pane key="2" tab="添加车辆型号">
          <div style="width:500px">
            <a-form ref="formRef" :model="state.form" :rules="state.rules" :labelCol="{flex:'100px'}" :wrapperCol="{flex:'auto'}">
              <a-form-item label="车辆品牌" name="brands">
                <a-select v-model:value="state.form.brands" show-search @change="queryVehicle" placeholder="查询/选择 车牌">
                  <a-select-option v-for=" item in state.carCustome" :key="item.value" :value="item.label">{{ item.label }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="车辆型号" name="model">
                <a-input v-model:value="state.form.model" />
              </a-form-item>
              <a-form-item>
                <a-row>
                  <a-col :span="24" style="text-align: right;margin-top:20px">
                    <a-button :loading="state.confirmLoading" type="primary" @click="save()">添加车辆型号</a-button>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-form>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </a-col>

</template>
<script setup>
// import { brand as brandAll, brandAdd, modelAddem } from '@/api/system/car'

import { onMounted, reactive, ref, toRefs, } from 'vue'
import { message } from 'ant-design-vue'
import { carBrand } from '@/api/utils.js'
import { brandAdd, modelAdd } from '@/api/system/vehiclemodel'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  fromKey: ref('1'),
  brands: [],
  vehicleBrands: [],
  customers: [],
  carCustome: [],
  confirmLoading: false,
  form: {},
  rules: {
    brand: [{ required: true, message: '请填写车辆品牌', trigger: 'blur' }],
    model: [{ required: true, message: '请填写车辆型号', trigger: 'blur' }],
    brands: [{ required: true, message: '请选择车辆品牌', trigger: 'blur' }],
  }
})
const formRefBrand = ref(null)
const formRef = ref(null)

const loadData = () => {
  state.loadingMore = true
  carBrand({}).then((res) => {
    store.dispatch('getCitys');
    state.carCustome = res.data
  })

  state.loadingMore = false
}

onMounted(loadData)

const save = () => {
  if (state.fromKey === '1') {
    formRefBrand.value.validate()
      .then(() => {
        state.confirmLoading = true
        brandAdd({ brandName: state.form.brand }).then(res => {
          console.log(res)
          if (res.code === 10000) {
            message.success(res.msg)
            state.form.brand = ''
            loadData()
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.confirmLoading = false
        })
      })
      .catch(error => {
        console.log(error)
      })
  } else {
    formRef.value.validate()
      .then(() => {
        state.confirmLoading = true
        let brandId = ''
        state.carCustome.forEach(item => {
          if (item.label === state.form.brands) {
            brandId = item.value
          }
        })
        modelAdd({ brandId: brandId, modelName: state.form.model }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.form.model = ''
            state.form.brands = ''
            loadData()
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.confirmLoading = false
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}
const changeBrandKey = (v) => {

}

const queryVehicle = (value, choice) => {
  state.vehicleBrands = choice
}




</script>
