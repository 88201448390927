import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 新增品牌
export function brandAdd (params) {
  return postBodyRequest('/cs/system/car/brand/add',params) 
}
// 新增车型
export function modelAdd (params) {
  return postBodyRequest('/cs/system/car/model/add',params) 
}
//车辆列表
export function getCarTree (params) {
  return getRequest('/cs/system/car/tree', params)
}

